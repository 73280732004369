<script setup lang="ts">
const { step } = useCart()
</script>

<template>
  <div class="flex flex-col w-full pt-20 h-screen">
    <CheckoutHeader />

    <CheckoutTitle />

    <main :class="step.showTitle ? 'mt-[89px]' : ''" class="flex-1">
      <slot />
    </main>

    <CheckoutFooter />
  </div>
</template>
